import React from 'react';
import {MDBAnimation} from 'mdbreact';
// import {Link} from "gatsby";
// import 'bootstrap';
import {Helmet} from 'react-helmet';
import {navigate} from 'gatsby';
import PreKickOff from './DetailUnits/PreKickOff';
import KickOff from './DetailUnits/KickOff';
import Increment from './DetailUnits/Increment';
import Delivery from './DetailUnits/Delivery';
import Background from '../../assets/images/services/main-page/topbanner-bg.jpg';
// import IphoneX from "../../assets/images/services/iot/iphone-x.png";
// import Lifeprint from "../../assets/images/services/iot/lifeprint-background.png";
 // import navigate from gatsby
const $ = typeof window !== `undefined` ? require('jquery') : null;
require('./detailservices.css');

class Iot extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $(document).ready(() => {
      $('#pre-kickoff-vertical').fadeIn();
      $('#pre-kickoff').addClass('pre-kickoff-active');
      $('.card').on('click', function (e) {
        const id = $(this).attr('id');
        const description = ['pre-kickoff', 'code', 'increment', 'delivery'];
        description.forEach((Id) => {
          $(`#${  Id  }-description`).hide();
          $(`#${  Id  }-vertical`).hide();
          $(`#${  Id}`).removeClass(`${Id  }-active`);
        });
        $(`#${  id  }-description`).fadeIn();
        $(`#${  id  }-vertical`).fadeIn();
        $(`#${  id}`).addClass(`${id  }-active`);
      });
    });
    function addMargin() {
      window.scrollTo(0, window.pageYOffset - 80);
    }
    window.addEventListener('hashchange', addMargin);
  }

  shouldComponentUpdate() {
    // remove when no jquery is used
    return false;
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>IoT Development Services | Tintash</title>
        </Helmet>
        <div>
          <section
            className="service-individual-page content-section-header"
            style={{backgroundImage: `url(${Background})`}}
          >
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <h1 className="latoBlack text-left text-white heading">IoT Development</h1>
                  <p className="latoRegular text-left text-white description mb-0">
                    We help our customers design innovative new IoT products to enable seamless and
                    smooth experiences.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="service-text-banner">
            {/* Tag line of iot development */}
            <div className="service-unicorn d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-12 slide-top service-text">
                    <span className="latoLight heavy-lift">Heavy-lifting for </span>
                    <span className="latoBold tag-line">Startups Unicorns </span>
                    <span className="latoLight heavy-lift connect">&amp;</span>
                    <span className="latoBold tag-line">Fortune 500s</span>
                  </div>
                  <div className="col-lg-4 col-12 clients-logos">
                    {/* <div class="d-flex flex-column"> */}
                    <div className="text-center unicorn-client">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/fortune-500/bedbathbeyond.png')
                            .default
                        }
                        alt="bedbathbeyond"
                      />
                      <p className="description latoBold mb-0">Fortune 500</p>
                    </div>
                    <div className="text-center unicorn-client">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/fortune-500/decorist.png')
                            .default
                        }
                        alt="decorist"
                      />
                      <p className="description latoBold mb-0">Acquired by Fortune 500</p>
                    </div>
                    <div className="text-center unicorn-client ">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/fortune-500/lifeprint.png')
                            .default
                        }
                        alt="lifeprint"
                      />
                      <p className="description latoBold mb-0">Apple’s Retail Partner</p>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Tag line of iot development */}
            {/* IoT section filter */}
            <div className="service-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <a href="#services" className="services__sections-anchor">
                      Capabilities
                    </a>
                  </div>
                  <div className="col-lg-3 col-6">
                    <a href="#process" className="services__sections-anchor">
                      Process
                    </a>
                  </div>
                  <div className="col-lg-3 col-6 text-lg-center">
                    <a href="#project" className="services__sections-anchor">
                      Featured Project
                    </a>
                  </div>
                  <div className="col-lg-3 col-6 text-lg-right">
                    <a href="#client" className="services__sections-anchor">
                      Client Engagements
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* IoT development section filter */}
            <div id="services" className="service-capabilities">
              {/* Capabilities */}
              <div className="capabilities">
                <div className="container ">
                  <img
                    width={74}
                    height={74}
                    src={require('../../assets/images/services/main-page/iot-hover.svg').default}
                  />
                  <h1 className="heading latoBold mb-0">IoT Development Capabilities</h1>
                  <div className="row services">
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#hardware">Hardware Design</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#software">Software &amp; BSP</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#product">Product Development</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#contract">Contract Manufacturing &amp; Logistics</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Capabilities */}
              {/* Hardware Design */}
              <div className="container">
                {/* Hardware development */}
                <div className="individual step-1" id="hardware">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid #ffb100'}}
                      >
                        Hardware Design
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <p className="description">
                        We provide custom hardware design and development through rapid prototyping
                        and user testing. We are experts in building embedded systems with
                        integrated sensors, embedded processing, and wired and wireless
                        communication.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12 pl-lg-0 ">
                          <p className="text-left expertise">
                            Rapid prototyping using development kits
                          </p>
                          <p className="text-left expertise">Proof of concept development</p>
                          <p className="text-left expertise">DFM (Design for manufacturing)</p>
                          <p className="text-left expertise">PCB, Schematic design</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">
                            ESP32/28, Arduino,ATMega, NRF-52/1, ARM, PIC, Blackfin DSP, Zynq, nVidia
                            GPU (TX1,TK1)
                          </p>
                          <p className="text-left expertise">3D design for better visualization</p>
                          <p className="text-left expertise">Microcontrollers/DSP/GPU</p>
                          <p className="text-left expertise">Altium</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title text-left">
                              <a
                                className="accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapseTwo"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapseTwo" className="panel-collapse collapse">
                            <div className="panel-body step-1">
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <p className="text-left expertise">
                                    Rapid prototyping using development kits
                                  </p>
                                  <p className="text-left expertise">
                                    Proof of concept development
                                  </p>
                                  <p className="text-left expertise">
                                    DFM (Design for manufacturing)
                                  </p>
                                  <p className="text-left expertise">PCB, Schematic design</p>
                                </div>
                                <div className="col-12 col-md-6">
                                  <p className="text-left expertise">
                                    ESP32/28, Arduino,ATMega, NRF-52/1, ARM, PIC, Blackfin DSP,
                                    Zynq, nVidia GPU (TX1,TK1)
                                  </p>
                                  <p className="text-left expertise">
                                    3D design for better visualization
                                  </p>
                                  <p className="text-left expertise">Microcontrollers/DSP/GPU</p>
                                  <p className="text-left expertise">Altium</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Hardware development */}
                {/* Software & BSP */}
                <div className="individual step-2" id="software">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid  #0ec573'}}
                      >
                        Software &amp; BSP
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <p className="description">
                        Our experienced engineering team delivers secure, industry-specific embedded
                        software development solutions and cloud integration. These solutions range
                        from web apps, mobile apps to entirely custom solutions made to fit every
                        set of unique requirements.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 pl-lg-0 col-12">
                          <p className="text-left expertise">
                            Embedded Linux, Free RTOS, Mongoose OS, Bare Metal
                          </p>
                          <p className="text-left expertise">Board Support Packages</p>
                          <p className="text-left expertise">Driver development</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Stack development</p>
                          <p className="text-left expertise">MQTT, AMQ, PubNub</p>
                          <p className="text-left expertise">Cloud IoT platform Integration</p>
                          <p className="text-left expertise">Analytics</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className=" accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapse"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapse" className="panel-collapse collapse">
                            <div className="panel-body step-2">
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <p className="text-left expertise">
                                    Embedded Linux, Free RTOS, Mongoose OS, Bare Metal
                                  </p>
                                  <p className="text-left expertise">Board Support Packages</p>
                                  <p className="text-left expertise">Driver development</p>
                                  <p className="text-left expertise">Stack development</p>
                                </div>
                                <div className="col-md-6 col-12">
                                  <p className="text-left expertise">MQTT, AMQ, PubNub</p>
                                  <p className="text-left expertise">
                                    Cloud IoT platform Integration
                                  </p>
                                  <p className="text-left expertise">Analytics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Software & BSP */}
                {/* Product Development */}
                <div className="individual step-1" id="product">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid #914df6'}}
                      >
                        Product Development
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <div className="vertical-line">
                        <p className="description">
                          From collecting, reporting and aggregating IoT data, to building machine
                          learning driven, end-to-end IoT systems, our developers have done it all.
                          They work closely with you to understand your requirements and innovate
                          new solutions.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12 pl-lg-0">
                          <p className="text-left expertise">IoT Ecosystem Development</p>
                          <p className="text-left expertise">External Sensors Integration</p>
                          <p className="text-left expertise">Google Cloud Platform</p>
                          <p className="text-left expertise">Amazon Web Services</p>
                          <p className="text-left expertise">Azure Web services</p>
                          <p className="text-center expertise">ThingsBoard</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Machine learning</p>
                          <p className="text-left expertise">Computer Vision</p>
                          <p className="text-left expertise">Web &amp; Mobile app integration</p>
                          <p className="text-left expertise">
                            Zigbee, Z-Wave, RFM 433Mhz, BLE, GSM/LTE, WiFi
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className=" accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapse-dev"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapse-dev" className="panel-collapse collapse">
                            <div className="panel-body step-1">
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <p className="text-left expertise">IoT Ecosystem Development</p>
                                  <p className="text-left expertise">
                                    External Sensors Integration
                                  </p>
                                  <p className="text-left expertise">Google Cloud Platform</p>
                                  <p className="text-left expertise">Amazon Web Services</p>
                                  <p className="text-left expertise">Azure Web services</p>
                                </div>
                                <div className="col-12 col-md-6">
                                  <p className="text-center expertise">ThingsBoard</p>
                                  <p className="text-left expertise">Machine learning</p>
                                  <p className="text-left expertise">Computer Vision</p>
                                  <p className="text-left expertise">
                                    Web &amp; Mobile app integration
                                  </p>
                                  <p className="text-left expertise">
                                    Zigbee, Z-Wave, RFM 433Mhz, BLE, GSM/LTE, WiFi
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product Development */}
                {/* Contract Manufacturing & Logistics */}
                <div className="individual step-2" id="contract">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid  #0093ff'}}
                      >
                        Contract Manufacturing &amp; Logistics
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <p className="description">
                        We provide timely, and quality manufacturing, BoM ordering and other
                        production cycle services.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12  pl-lg-0">
                          <p className="text-left expertise">Prototype manufacturing</p>
                          <p className="text-left expertise">Product manufacturing</p>
                        </div>
                        <div className="col-lg-6 col-12  pl-lg-0 pl-lg-4">
                          <p className="text-left expertise">BOM ordering &amp; assembly</p>
                          <p className="text-left expertise">Board bring up &amp; debugging</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className="accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapse-sys"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapse-sys" className="panel-collapse collapse">
                            <div className="panel-body step-2">
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <p className="text-left expertise">Prototype manufacturing</p>
                                  <p className="text-left expertise">Product manufacturing</p>
                                </div>
                                <div className="col-md-6 col-12">
                                  <p className="text-left expertise">BOM ordering &amp; assembly</p>
                                  <p className="text-left expertise">
                                    Board bring up &amp; debugging
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Contract Manufacturing & Logistics */}
              </div>
            </div>
            {/* IoT development Process */}
            <div id="process" className="development-process">
              <div className="container dev-process-desktop">
                <div className="row ">
                  <div className="col-12">
                    <h1 className="heading latoBold">IoT Development Process</h1>
                  </div>
                  {/* pre-kickoff */}
                  <PreKickOff />
                  {/* pre-kickoff */}
                  {/* kickoff */}
                  <KickOff />
                  {/* kickoff */}
                  {/* Increment */}
                  <Increment />
                  {/* Increment */}
                  {/* Delivery */}
                  <Delivery />
                  {/* Delivery */}
                  {/*
               ----------------------------------
               ------KICK OFF DESCRIPTION--------
               ----------------------------------
                */}
                  {/* Pre-Kickoff */}
                  <div className="col-12">
                    <div id="pre-kickoff-description" className="process-description">
                      <div className="d-flex d-row">
                        <div className="circle" style={{backgroundColor: '#0093FF'}} />
                        <div className="description">
                          <h1 className="heading latoBlack mb-3">Pre Kick-off</h1>
                          <p className="latoRegular">
                            We work with the client to analyze project scope and chart down the
                            requirements.
                          </p>
                          <p className="latoRegular">
                            A team is formed that is best suited for the client’s requirements.
                          </p>
                          <p className="latoRegular">
                            We populate the product backlog with epics and user stories, and the
                            client reviews them.
                          </p>
                          <p className="latoRegular">
                            <a href="/project-delivery-process" className="mr-1">
                              {' '}
                              Read More
                            </a>
                            about our pre kick-off delivery process.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pre-Kickoff */}
                  {/* kickoff */}
                  <div className="col-12">
                    <div
                      id="code-description"
                      className="process-description"
                      style={{display: 'none'}}
                    >
                      <div className="d-flex d-row">
                        <div className="circle" style={{backgroundColor: '#0EC573'}} />
                        <div className="description">
                          <h1 className="heading latoBlack mb-3">Kick-off</h1>
                          <p className="latoRegular">
                            We use modified Scrum for project management and delivery of each
                            project.
                          </p>
                          <p className="latoRegular">
                            <a href="/modified-scrum-model" className="mr-1">
                              {' '}
                              Read More
                            </a>
                            on why we modify the Scrum model for each project.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* kickoff */}
                  {/* Increment */}
                  <div className="col-12">
                    <div
                      id="increment-description"
                      className="process-description"
                      style={{display: 'none'}}
                    >
                      <div className="row">
                        <div className="pl-3">
                          <div className=" circle" style={{backgroundColor: '#F6654D'}} />
                        </div>
                        <div className="col-11 pl-0">
                          <div className="description col-11">
                            <h1 className="heading latoBlack mb-3">Increment and Release cycles</h1>
                            <p className="latoRegular">
                              We deliver an increment at the end of each sprint cycle.
                            </p>
                            <p className="latoRegular">
                              The client chooses the audience they would like the increment to be
                              released to.
                            </p>
                            <p className="latoRegular">
                              Our QA engineers keep track of all the tasks planned for each sprint
                              cycle, their progress, and testing status in a comprehensive sprint
                              report.
                            </p>
                            <p className="latoRegular">
                              Sprint reports are shared with every team member including clients to
                              have complete visibility of the overall progress of sprint.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Increment */}
                  {/* Delivery */}
                  <div className="col-12">
                    <div
                      id="delivery-description"
                      className="process-description"
                      style={{display: 'none'}}
                    >
                      <div className="row">
                        <div className="pl-3">
                          <div className="circle" style={{backgroundColor: '#FFB100'}} />
                        </div>
                        <div className="col-11 pl-0">
                          <div className="description">
                            <h1 className="heading latoBlack mb-3">Milestone Delivery</h1>
                            <p className="latoRegular">
                              The increments we deliver build up to a milestone.
                            </p>
                            <p className="latoRegular">
                              Our project managers work with clients across sprint planning and
                              sprint review meetings to develop an understanding of the scope that
                              needs to be covered in a milestone.
                            </p>
                            <p className="latoRegular">
                              Project Managers also make sure that they are aware of important
                              deadlines on which the product has to be demoed to stakeholders or
                              investors, or be released to customers.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Delivery */}
                </div>
              </div>
              <div className="container dev-process-mobile py-5">
                <div className="row">
                  <div className="col-12">
                    <h1 className="heading latoBold">IoT Development Process</h1>
                  </div>
                  <div className="col-12">
                    <div className="panel-group" id="accordion">
                      {/* pre kickoff */}
                      <div className="panel panel-default">
                        <div className="panel-heading py-3">
                          <div className="panel-title  text-left">
                            <a
                              className="accordion-toggle collapsed expertise-heading"
                              data-toggle="collapse"
                              href="#collapse-pre"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require('../../assets/images/services/development-process/discover.png')
                                    .default
                                }
                              />
                              <p className="description mb-0">Pre-kickoff</p>
                            </a>
                          </div>
                        </div>
                        <div id="collapse-pre" className="panel-collapse collapse">
                          <div className="panel-body step-">
                            <div className="row">
                              <div className="col-12">
                                <div id="pre-kickoff-description" className="process-description">
                                  <div className="d-flex d-row">
                                    <div className="description">
                                      <h1 className="heading latoBlack mb-3">Pre Kick-off</h1>
                                      <p className="latoRegular">
                                        We work with the client to analyze project scope and chart
                                        down the requirements.
                                      </p>
                                      <p className="latoRegular">
                                        A team is formed that is best suited for the client’s
                                        requirements.
                                      </p>
                                      <p className="latoRegular">
                                        We populate the product backlog with epics and user stories,
                                        and the client reviews them.
                                      </p>
                                      <p className="latoRegular">
                                        <a href="/project-delivery-process" className="mr-1">
                                          {' '}
                                          Read More
                                        </a>
                                        about our pre kick-off delivery process.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group" id="accordion">
                      {/* Code */}
                      <div className="panel panel-default">
                        <div className="panel-heading py-3">
                          <div className="panel-title  text-left">
                            <a
                              className="accordion-toggle collapsed expertise-heading"
                              data-toggle="collapse"
                              href="#collapse-code"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require('../../assets/images/services/development-process/code.png')
                                    .default
                                }
                              />
                              <p className="description mb-0">Kickoff</p>
                            </a>
                          </div>
                        </div>
                        <div id="collapse-code" className="panel-collapse collapse">
                          <div className="panel-body step-">
                            <div className="row">
                              <div className="col-12">
                                <div id="pre-kickoff-description" className="process-description">
                                  <div className="d-flex d-row">
                                    <div className="description">
                                      <h1 className="heading latoBlack mb-3">Kick-off</h1>
                                      <p className="latoRegular">
                                        We use modified Scrum for project management and delivery of
                                        each project
                                      </p>
                                      <p className="latoRegular">
                                        <a href="/modified-scrum-model" className="mr-1">
                                          {' '}
                                          Read More
                                        </a>
                                        on why we modify the Scrum model for each project.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group" id="accordion">
                      {/* increment */}
                      <div className="panel panel-default">
                        <div className="panel-heading py-3">
                          <div className="panel-title  text-left">
                            <a
                              className="accordion-toggle collapsed expertise-heading"
                              data-toggle="collapse"
                              href="#collapse-increment"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require('../../assets/images/services/development-process/increment.png')
                                    .default
                                }
                              />
                              <p className="description mb-0">Increment &amp; Release Cycles</p>
                            </a>
                          </div>
                        </div>
                        <div id="collapse-increment" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-12">
                                <div id="pre-kickoff-description" className="process-description">
                                  <div className="d-flex d-row">
                                    <div className="description">
                                      <h1 className="heading latoBlack mb-3">
                                        Increment and Release cycles
                                      </h1>
                                      <p className="latoRegular">
                                        We deliver an increment at the end of each sprint cycle.
                                      </p>
                                      <p className="latoRegular">
                                        The client chooses the audience they would like the
                                        increment to be released to.
                                      </p>
                                      <p className="latoRegular">
                                        Our QA engineers keep track of all the tasks planned for
                                        each sprint cycle, their progress, and testing status in a
                                        comprehensive sprint report.
                                      </p>
                                      <p className="latoRegular">
                                        Sprint reports are shared with every team member including
                                        clients to have complete visibility of the overall progress
                                        of sprint.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group" id="accordion">
                      {/* increment */}
                      <div className="panel panel-default">
                        <div className="panel-heading py-3">
                          <div className="panel-title  text-left">
                            <a
                              className="accordion-toggle collapsed expertise-heading"
                              data-toggle="collapse"
                              href="#collapse-milestone"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require('../../assets/images/services/development-process/milestone.png')
                                    .default
                                }
                              />
                              <p className="description mb-0">Delivery Milestones</p>
                            </a>
                          </div>
                        </div>
                        <div id="collapse-milestone" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-12">
                                <div id="pre-kickoff-description" className="process-description">
                                  <div className="d-flex d-row">
                                    <div className="description">
                                      <h1 className="heading latoBlack mb-3">Milestone Delivery</h1>
                                      <p className="latoRegular">
                                        The increments we deliver build up to a milestone.
                                      </p>
                                      <p className="latoRegular">
                                        Our project managers work with clients across sprint
                                        planning and sprint review meetings to develop an
                                        understanding of the scope that needs to be covered in a
                                        milestone.
                                      </p>
                                      <p className="latoRegular">
                                        Project Managers also make sure that they are aware of
                                        important deadlines on which the product has to be demoed to
                                        stakeholders or investors, or be released to customers.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* IoT development Process */}
            {/* Feature project */}
            <section id="project" className="feature-project">
              <div className="container">
                <h1 className="heading text-center">
                  <div className="horizontal-line mr-3" />
                  Featured Project
                  <div className="horizontal-line ml-3" />
                </h1>
              </div>
              {/* Desktop Tablet Pro Large Screen */}
              <div className="container-fluid d-flex justify-content-center align-items-center feature-project-desktop">
                <div className="mobile-img iphone-img">
                  <div className="float-left">
                    <div className="project lifeprint">
                      <h1 className="latoBlack project-heading pb-0 pt-0">
                        <img
                          src={
                            require('../../assets/images/services/iot/lifeprint-logo.png').default
                          }
                        />
                      </h1>
                      <p className="latoRegular project-description">
                        Lifeprint is an instant photo printer that not only allows you to print live
                        videos, but also share those printed videos with your friends, family, and
                        fans all over the world.
                      </p>
                      <a
                        target="_blank"
                        href="https://itunes.apple.com/us/app/lifeprint-photos/id972104022?mt=8"
                        className="btn-iot store"
                      >
                        App Store
                      </a>
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.lifeprint.lifeprintphotos&hl=en"
                        className="btn-iot store"
                      >
                        Play Store
                      </a>
                    </div>
                  </div>
                  <div className="float-right">
                    <div className="client-review">
                      <img
                        width="60px"
                        height="60px"
                        src={require('../../assets/images/services/iot/patrick.png').default}
                      />
                      <h2 className="latoBold name mb-0">Patrick Cosgrove</h2>
                      <p className="latoBold designation mb-0">CTO, LIFEPRINT</p>
                      <div className="background">
                        <p className="latoItalic review mb-0">
                          They have a strong, capable, and very hardworking team that is skilled in
                          developing Client Apps and associated Cloud Services. They were extremely
                          easy to work with, responsive to feedback, and clearly took great pride in
                          their work.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="screen-mobile  d-flex justify-content-center align-items-center"
                  style={{position: 'absolute'}}
                >
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide carousel-fade"
                    data-ride="carousel"
                    data-interval={3000}
                  >
                    <ol className="carousel-indicators" style={{bottom: '-24px'}}>
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to={0}
                        className="active"
                      />
                      <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                      <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          className="app-shot"
                          height={580}
                          width={287}
                          src={
                            require('../../assets/images/services/iot/lifeprint-first.png').default
                          }
                          alt="img"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="app-shot"
                          height={580}
                          width={287}
                          src={
                            require('../../assets/images/services/iot/lifeprint-second.png').default
                          }
                          alt="img"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="app-shot"
                          height={580}
                          width={287}
                          src={
                            require('../../assets/images/services/iot/lifeprint-third.png').default
                          }
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Desktop Tablet Pro Large Screen */}
              {/* Mobile Screen */}
              <div className="container feature-project-mobile">
                <div className="row d-flex d-column justify-content-center">
                  <div className="col-12">
                    <div className="project">
                      <h1 className="justify-content-start latoBlack project-heading mb-0">
                        <img
                          src={
                            require('../../assets/images/services/iot/lifeprint-logo.png').default
                          }
                        />
                      </h1>
                      <p className="latoRegular project-description">
                        Lifeprint is an instant photo printer that not only allows you to print live
                        videos, but also share those printed videos with your friends, family, and
                        fans all over the world.
                      </p>
                      <a
                        target="_blank"
                        href="https://itunes.apple.com/us/app/lifeprint-photos/id972104022?mt=8"
                        className="btn-iot store"
                        style={{}}
                      >
                        App Store
                      </a>
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.lifeprint.lifeprintphotos&hl=en"
                        className="btn-iot store"
                      >
                        Play Store
                      </a>
                    </div>
                    {/* Laptop Image */}
                    <div className="laptop">
                      <img
                        src={require('../../assets/images/services/iot/iot-mobile.png').default}
                      />
                    </div>
                    {/* Laptop Image */}
                    {/* Client Testimonial */}
                    <div className="text-center">
                      {/* Inline styling because this would take alot of classes */}
                      <div className="client-review" style={{height: '400px'}}>
                        <img
                          width={60}
                          height={60}
                          src={require('../../assets/images/services/iot/patrick.png').default}
                        />
                        <h2 className="latoBold name mb-0">Patrick Cosgrove</h2>
                        <p className="latoBold designation mb-0">CTO, LIFEPRINT</p>
                        <div className="background">
                          <p className="latoItalic review mb-0 text-left">
                            They have a strong, capable, and very hardworking team that is skilled
                            in developing Client Apps and associated Cloud Services. They were
                            extremely easy to work with, responsive to feedback, and clearly took
                            great pride in their work.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Client Testimonial */}
                  </div>
                </div>
              </div>
              {/* Mobile Screen */}
            </section>
            {/* Feature project */}
            {/* Client engagements */}
            <div id="client" className="container">
              <div className="client-engagement">
                <div className="row d-flex flex-row">
                  <div className="col-12">
                    <h1 className="heading latoBold">Client Engagements</h1>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/trackify-logo.png').default
                        }
                        alt="trackify-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/nitrocam-logo.png').default
                        }
                        alt="nitrocam-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/lumenate-solutions-logo.png')
                            .default
                        }
                        alt="lumenate-solutions-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Client engagements */}
          </section>
        </div>
      </>
    );
  }
}

export default Iot;
